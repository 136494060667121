// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
$md-red: (
    50 : #fee8e7,
    100 : #fcc7c3,
    200 : #faa19b,
    300 : #f87b73,
    400 : #f75f55,
    500 : #f54337,
    600 : #f43d31,
    700 : #f2342a,
    800 : #f02c23,
    900 : #ee1e16,
    A100 : #ffffff,
    A200 : #ffebeb,
    A400 : #ffbab8,
    A700 : #ffa19e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);





// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-panel-primary: mat-palette($md-red);
$app-panel-accent: mat-palette($mat-green);

// The warn palette is optional (defaults to red).
$app-panel-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-panel-theme: mat-light-theme((
  color: (
    primary: $app-panel-primary,
    accent: $app-panel-accent,
    warn: $app-panel-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-panel-theme);
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html, body {
   height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.primary-bg{
  background-color: mat-color($app-panel-primary);
}
.primary-color{
  color: mat-color($app-panel-primary);
}
.secondary-bg{
  background-color: mat-color($app-panel-accent);
}
.menu-button-bg{
  background-color: mat-color($app-panel-accent);
}
.menu-button-bg:hover{
  background-color: mat-color($app-panel-accent,300);
}
.p-color{
  color:mat-color($app-panel-primary);
}
.spacer{
  flex: 1 1 auto;
}
::-webkit-scrollbar {
  width: 8px;
}

.small-icon-button {
  width: 48px !important;
  height: 48px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role=img] {
     width: 32px;
     height: 32px;
     font-size: 32px;

     svg {
        width: 32px;
        height: 32px;
     }
  }

  .mat-mdc-button-touch-target {
     width: 48px !important;
     height: 48px !important;
  }
}

.ab-notification .mat-dialog-container {
  padding: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mat-sidenav{
  border-right:0px;
}
.available-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 18px;
  border: 2px solid green;
  text-align: center;
  color:  #fff;
  background-color: green;
  font-size: 12px;
}

/* cyan:#00BCD4
   warn:#F44336
*/
.toolbar{
  display: flex;
  align-items: center;
  height: 60px;
  margin-top:1rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-right:1.5rem;
}
.cal-day-selected,
      .cal-day-selected:hover {
        background-color: deeppink !important;
      }

      #ccsingle {
        position: absolute;
        right: 15px;
        top: 20px;
      }
      
      
      #ccsingle svg {
        width: 90px;
        max-height: 50px;
      }
      
      .ccIconTile svg {
        width: 90px;
        max-height: 50px;
        padding-right: .6rem;
      }
      
      .loading-screen-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
@media only screen and (max-width: 600px) {
  .toolbar{
    height: 60px;
    margin-left: 1.5rem;
    margin-right:1.5rem;
  }
}
